import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment, setDefaultPinDurationInDays, setUseDeviceVisibleToUser } from 'src/environments/environment';
import { Platform, ToastController } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { NetworkService } from '../services/network.service';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { MqttProjectService } from './mqtt-project.service';

import { setUseDali } from 'src/environments/environment';
import { ApiConfigProviderService, ConfigProviderOutput } from './api-config-provider.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { Configuration } from '../models/configuration.model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { CardReaderService } from 'src/app/shared/services/card-reader.service';
import { IStoredToken } from 'src/app/modules/settings/components/object-configurations/object-configurations.component';


@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private readonly CONFIG_URL = 'assets/config/config.json';
  private readonly PROD_CONFIG_URL = '../config/config.json';
  private fullConfig: ConfigProviderOutput;
  fullConfig$ = new BehaviorSubject<ConfigProviderOutput | undefined>(undefined);

  nativeConfigDirName = 'ABAS';
  nativeConfigDirParentLocation: string;
  nativeConfigFileName = 'abasConfig.json';
  iosPrefix = 'capacitor://localhost/_capacitor_file_';
  androidPrefix = 'https://localhost/_capacitor_file_';
  prefix: string;
  localNetworkPingTimeout = 2000;

  private checkedConfigForUpdates = false;

  nativeConfigsArrayFileName = 'storedAbasConfigsArray.json';
  nativeTokensArrayFileName = 'storedAbasTokensArray.json';

  constructor(private http: HttpClient,
              private platform: Platform,
              private file: File,
              private networkService: NetworkService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private mqttProjectService: MqttProjectService,
              private apiConfigProviderService: ApiConfigProviderService,
              private pipe: GenComTranslatePipe,
              private toastController: ToastController,
              private cardReaderService: CardReaderService,
              ) {}


  setConfig(fullConfig: ConfigProviderOutput) {
    this.fullConfig = fullConfig;
    this.fullConfig$.next(fullConfig);
  }

  getConfig() {
    return this.fullConfig;
  }

  resetCheckedConfigForUpdates() {
    this.checkedConfigForUpdates = false;
  }

  getCheckedConfigForUpdates() {
    return this.checkedConfigForUpdates;
  }

  public async configStartup() {
      await this.platform.ready();
      if (this.platform.is('android')) {
        this.nativeConfigDirParentLocation = this.file.dataDirectory;
        this.prefix = this.androidPrefix;
        await this.loadMobileConfig();
      } else if (this.platform.is('ios')) {
        this.nativeConfigDirParentLocation = this.file.documentsDirectory;
        this.prefix = this.iosPrefix;
        await this.loadMobileConfig();
      } else {
      await this.loadWebConfig();
      }
  }

  async loadMobileConfig(retry = false){

    try {
      let fullCfg: ConfigProviderOutput;
      try { // config in storage
        fullCfg = await this.getConfigFromStorage();
      } catch (e) {// no config in storage
        this.router.navigate(['/landing']);
        return;
      }

      await this.saveConfigToArrayIfConfigIsNotPreviouslyStored(fullCfg);
      this.setConfig(fullCfg);
      setUseDali(this.fullConfig.configuration.USE_DALI);
      setUseDeviceVisibleToUser(this.fullConfig.configuration.USE_DEVICE_VISIBLE_TO_USER);
      setDefaultPinDurationInDays(this.fullConfig.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
      try {
        await lastValueFrom(this.http.get(`${this.fullConfig.configuration.API_BASE_LOCAL_URL}/status`, {responseType: 'text'})
        .pipe(timeout(this.localNetworkPingTimeout)));
        await this.localNetworkStartup(this.fullConfig.configuration, retry);
      } catch (err) {
        await this.publicNetworkStartup(this.fullConfig.configuration, retry);
      }
    } catch (error){ // can not connect to network
      try {
        const configsArray: ConfigProviderOutput[] = await this.getConfigsArrayFromStorage();
        if (configsArray.length > 1 ) {// if there are multiple configs available goto landing
          this.router.navigate(['/landing']);
        } else { // if only one config available goto error page
          this.router.navigate(['/network-error']);
        }
      }
      catch (er) { // no network and no configs array stored
        this.router.navigate(['/network-error']);
      }



    }
  }

  private async loadWebConfig() {
    let configUrl: string;
    if (environment.production) {
      configUrl = this.PROD_CONFIG_URL;
    } else {
      configUrl = this.CONFIG_URL;
    }
    const webConfig: Configuration = await lastValueFrom(this.http.get<Configuration>(configUrl));

    const fullConfig = {
      _id: '',
      configuration: webConfig,
      locationId: '',
      type: 'abas'
    };
    this.setConfig(fullConfig);
    setUseDali(this.fullConfig.configuration.USE_DALI);
    setUseDeviceVisibleToUser(this.fullConfig.configuration.USE_DEVICE_VISIBLE_TO_USER);
    setDefaultPinDurationInDays(this.fullConfig.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
    if (document.location.hostname === this.fullConfig.configuration.OVPN_URL) {
      this.publicNetworkStartup(this.fullConfig.configuration);
    } else {
      this.localNetworkStartup(this.fullConfig.configuration);
    }
  }


  private async localNetworkStartup(config: Configuration, retry = false) {
    this.networkService.setLocalNetwork(config);
    this.networkService.initNetworkSwitcher(config);
    if (this.authenticationService.isValid()) {
      try {
      await lastValueFrom(this.authenticationService.getUserByToken());
      this.mqttProjectService.unsubscribeFromMqtt();
      this.mqttProjectService.connect(config.MQTT_LOCAL_SETTINGS)
      await this.cardReaderService.initialize()
      await this.mqttProjectService.initMqttForProject();
      // on startup routes to home automaticaly, no need for router
      if (retry) {
        this.router.navigate(['/home']);
      }

    } catch (err) {
      console.log(err);
    }
    } else {
      this.router.navigate(['/login']);
    }
  }


  private async publicNetworkStartup(config: Configuration, retry = false) {
    console.log('public network startup');
    this.networkService.setPublicNetwork(config);
    this.networkService.initNetworkSwitcher(config);
    if (this.authenticationService.isValid()) {
      await lastValueFrom(this.authenticationService.getUserByToken());
      this.mqttProjectService.unsubscribeFromMqtt();
      this.mqttProjectService.connect(config.MQTT_OVPN_SETTINGS)
      await this.cardReaderService.initialize()
      await this.mqttProjectService.initMqttForProject();
      // on startup routes to home automaticaly, no need for router
      if (retry) {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveConfigToStorage(fullConfig: ConfigProviderOutput) {
    try {
      await this.file.createDir(this.nativeConfigDirParentLocation, `${this.nativeConfigDirName}`, true);
      await this.file.writeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeConfigFileName,
        JSON.stringify(fullConfig),
        {replace: true}
      );
    }
    catch (err){
      console.log(err);
    }
  }

  async getConfigFromStorage(): Promise<ConfigProviderOutput> {
    await this.file.checkDir(`${this.nativeConfigDirParentLocation}`, this.nativeConfigDirName);
    await this.file.checkFile(`${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/`, this.nativeConfigFileName);
    // const fullpath = await this.file.resolveLocalFilesystemUrl(`${this.configDirParentLocation}${this.configDirName}/${this.fileName}`)
    // this.file.readAsText on Cordova file plugin does not work, so http.get had to be used,
    //
    // const path = this.webview.convertFileSrc(`${this.configDirParentLocation}abas/${this.fileName}`)
    // cordova-webview  plugin has convertFileSrc() function which gets correct path for http call but to avoid using another plugin
    // local implementation of function is used
    // incorect path for http call 'file:///storage/emulated/0/abas/newFile.txt'
    // correct path `http://localhost/_capacitor_file_/storage/emulated/0/abas/newFile.txt`
    const split = `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/${this.nativeConfigFileName}`.split('//', 2)[1];
    const path = `${this.prefix}${split}`;
    return await lastValueFrom(this.http.get<ConfigProviderOutput>(path));
  }

  async deleteConfigFromStorage() {
    try {
      await this.file.removeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeConfigFileName);
    }
    catch (err){
      console.log(err);
    }
  }

  checkConfigForUpdates() {
    if (!this.checkedConfigForUpdates) {
      this.checkedConfigForUpdates = true;
      this.apiConfigProviderService.getConfig(this.fullConfig._id)
      .pipe(timeout(5000))
      .subscribe({
        next: (freshConfig: ConfigProviderOutput) => {
          if (Object.keys(freshConfig).length === 0) { // check for empty object
            return;
          }
          if (!Configuration.configUrlsAreValid(freshConfig.configuration)) { // check for invalid config urls
            this.presentToast('Error updating configuration. Please contact support.');
            return;
          }
          if (JSON.stringify(this.fullConfig) !== JSON.stringify(freshConfig)) {
            this.saveConfigToStorage(freshConfig);
            this.updateConfigsArray(freshConfig)
            this.presentToast('Configuration updated. Restart app to apply changes.');
            console.log(`Configuration updated. Restart app to apply changes.`);
          } else {
            console.log(`No changes to configuration.`);
          }
        },
        error: (err) => {
          console.log(`Configuration server not available. ${JSON.stringify(err)}`);
        }
      });
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: this.pipe.transform(msg),
      // duration: 2000,
      buttons: [
       {
          text: this.pipe.transform('Dismiss'),
          role: 'cancel',
          /* handler: () => {
            console.log('Cancel clicked');
          } */
        }
      ]

    });
    toast.present();
  }

  async updateConfigsArray(freshConfig: ConfigProviderOutput) {
    try {
      const configsArray: ConfigProviderOutput[] = await this.getConfigsArrayFromStorage();
      const configToStoreIndex = configsArray.findIndex((c)=> {
        return c._id === freshConfig._id
      })

      if (configToStoreIndex !== -1) {
        configsArray[configToStoreIndex] = freshConfig;
        await this.saveConfigsArrayToStorage(configsArray);
      }

    } catch (err) {
      console.log("No config array to update")
    }
  }

  async saveConfigToArrayIfConfigIsNotPreviouslyStored(config: ConfigProviderOutput) {
    try {
      const configsArray: ConfigProviderOutput[] = await this.getConfigsArrayFromStorage();
      const configToStoreIndex = configsArray.findIndex((c)=> {
        return c._id === config._id
      })

      if (configToStoreIndex === -1) {
        configsArray.push(config);
        await this.saveConfigsArrayToStorage(configsArray);
      }

    } catch (err) {
      await this.saveConfigsArrayToStorage([config]);
    }
  }

  async getConfigsArrayFromStorage(): Promise<ConfigProviderOutput[]> {
    await this.file.checkDir(`${this.nativeConfigDirParentLocation}`, this.nativeConfigDirName);
    await this.file.checkFile(`${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/`, this.nativeConfigsArrayFileName);
    const split = `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/${this.nativeConfigsArrayFileName}`.split('//', 2)[1];
    const path = `${this.prefix}${split}`;
    return await lastValueFrom(this.http.get<ConfigProviderOutput[]>(path));
  }

  async saveConfigsArrayToStorage(configsArray: ConfigProviderOutput[]) {
    try {
      await this.file.createDir(this.nativeConfigDirParentLocation, `${this.nativeConfigDirName}`, true);
      await this.file.writeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeConfigsArrayFileName,
        JSON.stringify(configsArray),
        {replace: true}
      );
    }
    catch (err){
      console.log(err);
    }
  }

  async getTokensArrayFromStorage(): Promise<IStoredToken[]> {
    await this.file.checkDir(`${this.nativeConfigDirParentLocation}`, this.nativeConfigDirName);
    await this.file.checkFile(`${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/`, this.nativeTokensArrayFileName);
    const split = `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/${this.nativeTokensArrayFileName}`.split('//', 2)[1];
    const path = `${this.prefix}${split}`;
    return await lastValueFrom(this.http.get<IStoredToken[]>(path));
  }

  async saveTokensArrayToStorage(tokensArray: IStoredToken[]) {
    try {
      await this.file.createDir(this.nativeConfigDirParentLocation, `${this.nativeConfigDirName}`, true);
      await this.file.writeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeTokensArrayFileName,
        JSON.stringify(tokensArray),
        {replace: true}
      );
    }
    catch (err){
      console.log(err);
    }
  }


}

<div class="content-container">
    <div class="content-header font-size22">
        {{'Blinds settings' | translate}}
    </div>
    <div class="flex-column content-form-group">
        <div class="flex-column margin-bottom10">
            <div>{{'Time up' | translate}}</div>
            <input type="text" [(ngModel)]="blindsV2_time_up.value">
        </div>
        <div class="flex-column margin-bottom10">
            <div>{{'Time down' | translate}}</div>
            <input type="text" [(ngModel)]="blindsV2_time_down.value">
        </div>
        <div class="flex-column margin-bottom10">
            <div>{{'Direction' | translate}}</div>
            <select
            [(ngModel)]="blindsV2_direction.value"
          >
            <option value="0">{{ 'UP' | translate }}</option>
            <option value="1">{{ 'DOWN' | translate }}</option>
          </select>
        </div>
    </div>

    <div class="flex-row-center">
        <ion-button class="margin-right20"  (click)="cancel()" size="small">{{'Cancel' | translate}}</ion-button>
        <ion-button  (click)="confirm()" size="small">OK</ion-button>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';


@Pipe({
  name: 'filterBlindsV2'
})
export class FilterBlindsV2Pipe implements PipeTransform {

  constructor() {}

  transform(equipmentProperties: Property[]): Property[] {
    if (!equipmentProperties) {
      return [];
    }
    return equipmentProperties.filter (prop => !Property.isBlindV2Up(prop) && 
                                               !Property.isBlindV2Down(prop) &&  
                                               !Property.isBlindV2TimeDown(prop) && 
                                               !Property.isBlindV2TimeUp(prop) && 
                                               !Property.isBlindV2Direction(prop))
  }
}


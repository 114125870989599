import { Injectable } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { EquipmentBinaryComponent } from 'src/app/shared/components/equipment/equipment-binary/equipment-binary.component';
import { EquipmentNumberComponent } from '../components/equipment/equipment-number/equipment-number.component';
import { EquipmentPropertyTypesService } from './equipment-property-types.service';
import { EquipmentPropertyComponentToLoad } from 'src/app/core/models/equipment-property-component-to-load.model';
import { OutputModeComponent } from '../components/equipment/output-mode/output-mode.component';

export const equipmentPropertyTypeComponents = {
  Blinds: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Moving', nameForFalse: 'Not moving' }},
  BlindsV2_direction: {component: EquipmentNumberComponent, data: {unitOfMeasurement: '%'}},
  Curtains: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Opened', nameForFalse: 'Closed'  }},
  Overhang: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Opened', nameForFalse: 'Closed'  }},

  Window: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Opened', nameForFalse: 'Closed',  icon: 'i-window-bl', iconOff: 'i-window-closed-bl' }},
  BalconyDoor: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Opened', nameForFalse: 'Closed'  }},

  FanCoilV1: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Low', nameForFalse: 'Off',  icon: 'i-fan-bl', iconOff: 'i-fan-bl',  canBeActive: true, location: 'room' }},
  FanCoilV2: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Mid', nameForFalse: 'Off',  icon: 'i-fan-bl', iconOff: 'i-fan-bl',  canBeActive: true, location: 'room' }},
  FanCoilV3: {component: EquipmentBinaryComponent, data: {nameForTrue: 'High', nameForFalse: 'Off',  icon: 'i-fan-bl', iconOff: 'i-fan-bl',  canBeActive: true, location: 'room' }},
  FanCoilVAuto: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Auto', nameForFalse: 'Off',  icon: 'i-fan-bl', iconOff: 'i-fan-bl',  canBeActive: true, location: 'room' }},
  RadiatorRoom: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  canBeActive: true, location: 'room',  icon: 'i-room-radiator-bl', iconOff: 'i-room-radiator-bl' }},
  HvacRoomControlRadiator: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-room-radiator-wh', iconOff: 'i-room-radiator-wh', location: 'room' }},
  HvacBathroomControlRadiator: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-radiator-bathroom', iconOff: 'i-radiator-bathroom', location: 'bathroom' }},
  HvacRoomControlFloorheating: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-floor-heating', iconOff: 'i-floor-heating', location: 'room' }},
  HvacBathroomControlFloorheating: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-floor-heating', iconOff: 'i-floor-heating', location: 'bathroom' }},

  RadiatorBathroom: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-radiator-bathroom-bl', iconOff: 'i-radiator-bathroom-bl', canBeActive: true, location: 'bathroom' }},
  FloorheatingRoom : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-floor-heating-bl', iconOff: 'i-floor-heating-bl', canBeActive: true, location: 'room'}},
  FloorheatingBathroom : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-floor-heating-bl', iconOff: 'i-floor-heating-bl', canBeActive: true, location: 'bathroom' }},
  FanBathroom : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-fan-bl', iconOff: 'i-fan-bl', canBeActive: true, location: 'bathroom' }},
  LightSwitch : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  WaterValve : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  DCPowerForLED: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  WelcomeLight: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  canBeActive: true }},
  Light : {component: EquipmentBinaryComponent, data: {primaryProperty: true, nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-light-on-bl', iconOff: 'i-light-on-bl', canBeActive: true}},
  LightDimming : {component: EquipmentBinaryComponent, data: {nameForTrue: '', nameForFalse: '',  icon: 'i-dimmable-light-wh', iconOff: 'i-dimmable-light-wh', isDimmableLight: true }},
  HVACActive : {component: EquipmentBinaryComponent, data: {primaryProperty: true, nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-active-bl', iconOff: 'i-active-bl', showSpecialHTML: true, location: 'room' }},
  HVACStatus: {component: EquipmentBinaryComponent, data: {primaryProperty: true, nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-active-bl', iconOff: 'i-active-bl', showSpecialHTML: true, location: 'room' }},
  ValveHeating  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  ValveCooling  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  Dumper: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  ElectricStrike: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  Contactor: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-contactor-bl', iconOff: 'i-contactor-bl', canBeActive: true }},
  SOSinput : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},
  PIRinput: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  }},


  HvacRoomControlFancoilOff   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Off', nameForFalse: '',  icon: 'i-battery-off', iconOff: 'i-battery-off', location: 'room' }},
  HvacRoomControlFancoilLow   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Low', nameForFalse: '',  icon: 'i-battery-low', iconOff: 'i-battery-low', location: 'room' }},
  HvacRoomControlFancoilMedium   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Medium', nameForFalse: '',  location: 'room' }},
  HvacRoomControlFancoilHigh   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'High', nameForFalse: '',  location: 'room' }},
  HvacRoomControlFancoilAuto   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Auto', nameForFalse: '',  icon: 'i-battery-auto', iconOff: 'i-battery-auto', location: 'room' }},



  CardDeposited : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  NewCardFlag : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  CardOnReader  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  CardOnTray  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  DND : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  CallReception : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  MinibarWasOpened : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  RoomArmed  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-room-armed-bl', iconOff: 'i-room-armed-bl', }},
  IgnoreCardTray  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-card-tray-wh', iconOff: 'i-card-tray-wh', }},
  IgnoreWindowContact  : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-window-wh', iconOff: 'i-window-wh', }},
  BurglaryIncludePIR : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-infrared-wh', iconOff: 'i-infrared-wh', }},
  BurglaryIncludeWindow : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-burglary-window-wh', iconOff: 'i-burglary-window-wh', }},
  MUR   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  PanelBlock   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  icon: 'i-panel-wh', iconOff: 'i-panel-wh', }},
  AcVoltage   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},
  Fuse   : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Yes', nameForFalse: 'No',  }},

  // alarms
  Burglary: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-burglary-bl', iconOff: 'i-burglary-bl', }},
  SafeBurglary : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-safe-bl', iconOff: 'i-safe-bl', }},
  BurglaryDetectedByPIR : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-infrared-bl', iconOff: 'i-infrared-bl', }},
  BurglaryDetectedWindow : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-burglary-window-bl', iconOff: 'i-burglary-window-bl', }},
  BurglaryDetectedDoor : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-burglary-boor_bl', iconOff: 'i-burglary-boor_bl', }},
  SOS : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  }},
  Flood : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal', icon: 'i-flood', iconOff: 'i-flood', }},
  Fire : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  }},
  CO2 : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  }},
  Wind : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal', icon: 'i-wind', iconOff: 'i-wind', }},
  HighControllerTemperature : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  }},
  HVACLargeTempDiff : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  }},
  SafeCommError : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-safe-error-bl', iconOff: 'i-safe-error-bl', }},
  // CommunicationError : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Alarm', nameForFalse: 'Normal',  icon: 'i-communication-error-bl' }},

  HVACHeatingCoolingMode : {component: EquipmentBinaryComponent, data: {nameForTrue: 'Cooling', nameForFalse: 'Heating',  iconTrue: 'i-cooling-bl', iconFalse: 'i-heating-bl', location: 'room' }},

  RoomTemperature : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-room-bl', iconOff: 'i-room-bl', location: 'room'}},
  RoomTemperatureSet : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer', showSpecialHTML: true }},
  BathroomTemperature : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-bathroom-bl', iconOff: 'i-bathroom-bl', location: 'bathroom'}},
  BathroomTemperatureSet : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer', showSpecialHTML: true}},
  BathroomFloorTemperature : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',   icon: 'i-floor-heating-bl', iconOff: 'i-floor-heating-bl', location: 'bathroom' }},
  BathroomFloorTemperatureSet : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-floor-heating', iconOff: 'i-floor-heating',}},
  InternalControllerTemperature : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC', }},

  HvacRoomFloorconvectorV1: {component: EquipmentBinaryComponent, data: {nameForTrue: '1', nameForFalse: 'Off',  icon: 'i-convection-heater-bl', iconOff: 'i-convection-heater-bl', canBeActive: true, location: 'room' }},
  HvacRoomFloorconvectorV2: {component: EquipmentBinaryComponent, data: {nameForTrue: '2', nameForFalse: 'Off',  icon: 'i-convection-heater-bl', iconOff: 'i-convection-heater-bl', canBeActive: true, location: 'room' }},
  HvacRoomFloorconvectorV3: {component: EquipmentBinaryComponent, data: {nameForTrue: '3', nameForFalse: 'Off',  icon: 'i-convection-heater-bl', iconOff: 'i-convection-heater-bl', canBeActive: true, location: 'room' }},

  HvacRoomControlFloorconvector: {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off',  icon: 'i-convection-heater-wh', iconOff: 'i-convection-heater-wh', location: 'room' }},

  HVACRoomEcoHyst1: {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACRoomEcoHyst2 : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACBathroomEcoHyst1 : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACBathroomEcoHyst2: {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACRegulation : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACCalibration : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACRoomBaseSet : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},
  HVACBathroomBaseSet : {component: EquipmentNumberComponent, data: {unitOfMeasurement: 'ºC',  icon: 'i-thermometer', iconOff: 'i-thermometer',}},

  Door: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Open', nameForFalse: 'Closed',  icon: 'i-door-bl', iconOff: 'i-door-bl', }},
  SafeDoor: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Open', nameForFalse: 'Closed',  icon: 'i-safe-door-bl', iconOff: 'i-safe-door-bl', }},
  DoorLock: {component: EquipmentBinaryComponent, data: {nameForTrue: 'Locked', nameForFalse: 'Unlocked',  icon: 'i-door-lock-bl', iconOff: 'i-door-lock-bl', }},

  Luxomat : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off' }},
  LuxomatBroadcast : {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off' }},

  OutputMode : {component: OutputModeComponent},
};

@Injectable({
  providedIn: 'root'
})
export class EquipmentComponentGenService {
  genericPropertyBinaryComponent = {component: EquipmentBinaryComponent, data: {nameForTrue: 'On', nameForFalse: 'Off'}};
  genericPropertyNumberComponent = {component: EquipmentNumberComponent, data: {unitOfMeasurement: '', icon: '', iconOff: ''}};

  constructor(private equipmentPropertyTypesService: EquipmentPropertyTypesService) {}

  getComponent(equipmentProperty: Property, parentDevice: Device) {
      const equipmentPropertyType: PropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyType(equipmentProperty.type);
      return this.createNewComponentToLoad(equipmentProperty, parentDevice, equipmentPropertyType);
  }

  getComponentForAdmin(equipmentProperty: Property, parentDevice: Device) {
    const equipmentPropertyType: PropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyTypeForAdmin(equipmentProperty.type);
    return this.createNewComponentToLoad(equipmentProperty, parentDevice, equipmentPropertyType);
  }

getComponentForStatus(equipmentProperty: Property, parentDevice: Device) {
  const equipmentPropertyType: PropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(equipmentProperty.type);
  return this.createNewComponentToLoad(equipmentProperty, parentDevice, equipmentPropertyType);
}

  getTextualRepresentationOfValue(equipmentProperty: Property) {
    let equipmentPropertyType: PropertyType;
    let componentData;
    if (this.equipmentPropertyTypesService.getEquipmentPropertyType(equipmentProperty.type)) {
      equipmentPropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyType(equipmentProperty.type);
      componentData = equipmentPropertyTypeComponents[equipmentPropertyType.Name]?.data;
    }else if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForAdmin(equipmentProperty.type)) {
      equipmentPropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyTypeForAdmin(equipmentProperty.type);
      componentData = equipmentPropertyTypeComponents[equipmentPropertyType.Name]?.data;
    }else if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(equipmentProperty.type)) {
      equipmentPropertyType = this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(equipmentProperty.type);
      componentData = equipmentPropertyTypeComponents[equipmentPropertyType.Name]?.data;
    }
    if (componentData) {
      return componentData;
    } else {
      if (equipmentPropertyType.ValueType === 'binary' ) {
        return this.genericPropertyBinaryComponent.data;
      } else if (equipmentPropertyType.ValueType === 'integer') {
        return this.genericPropertyNumberComponent.data;
      }
    }
  }

  createNewComponentToLoad(equipmentProperty: Property,  parentDevice: Device, equipmentPropertyType: PropertyType) {
    let componentToLoad: EquipmentPropertyComponentToLoad;
    if (equipmentPropertyTypeComponents[equipmentPropertyType.Name]) {
      componentToLoad = new EquipmentPropertyComponentToLoad(
        equipmentPropertyTypeComponents[equipmentPropertyType.Name]?.component,
        equipmentProperty,
        equipmentPropertyType,
        parentDevice,
        equipmentPropertyTypeComponents[equipmentPropertyType.Name]?.data );
    } else {
      if (equipmentPropertyType.ValueType === 'binary' ) {
      componentToLoad = new EquipmentPropertyComponentToLoad(
        this.genericPropertyBinaryComponent.component,
        equipmentProperty,
        equipmentPropertyType,
        parentDevice,
        this.genericPropertyBinaryComponent.data );
      } else if (equipmentPropertyType.ValueType === 'integer'){
        componentToLoad = new EquipmentPropertyComponentToLoad(
          this.genericPropertyNumberComponent.component,
          equipmentProperty,
          equipmentPropertyType,
          parentDevice,
          this.genericPropertyNumberComponent.data );
      }
    }
    return componentToLoad;

  }
}

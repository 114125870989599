import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-modal-blindsv2-settings',
  templateUrl: './modal-blindsv2-settings.component.html',
  styleUrls: ['./modal-blindsv2-settings.component.scss']
})
export class ModalBlindsv2SettingsComponent implements OnInit {

  @Input() parentDevice: Device;
  @Input() blindsV2_time_up: Property;
  @Input() blindsV2_time_down: Property;
  @Input() blindsV2_direction: Property;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  confirm() {
    this.modalCtrl.dismiss(true);
  }

}

<!-- <div class="flex-row space-between margin-bottom20">
  <div>{{getRoom(deviceInSetup)?.name}}</div>
  <div>{{getFloor(deviceInSetup)?.name}}</div>
</div> -->

<div *ngIf="step === 'select property'">
  <div class="label">{{ 'select_device_property_for_new_action' | translate }}</div>
  <div *ngFor="let equipmentProperty of equipmentProperties | filterBlindsV2">
    <div (click)="selectEquipProp(equipmentProperty)" class="property-container">
      {{ getEquipmentPropertyName(equipmentProperty) }}
    </div>
  </div>
</div>

<div *ngIf="step === 'select action'">
  <div *ngIf="deviceIsDali">
    <app-rgbw-light *ngIf="daliLightIsRgb(deviceInSetup)" [daliAction]="deviceInSetupAction" [daliRgbLightInput]="deviceInSetup" [mode]="'action'" (deviceAction$)="actionChanged($event)"></app-rgbw-light>
    <app-dimmable-dali-light *ngIf="daliLightIsNormal(deviceInSetup)" [daliAction]="deviceInSetupAction" [daliLight]="deviceInSetup" [mode]="'action'" (deviceAction$)="actionChanged($event)"></app-dimmable-dali-light>
    <app-dimmable-light-group *ngIf="isDaliGroup(deviceInSetup)" [daliAction]="deviceInSetupAction" [daliLightsGroup]="deviceInSetup" [mode]="'action'" (deviceAction$)="actionChanged($event)"></app-dimmable-light-group>
    <app-dali-blind *ngIf="isDaliBlinds(deviceInSetup)" [daliAction]="deviceInSetupAction" [daliBlind]="deviceInSetup" [mode]="'action'" (deviceAction$)="actionChanged($event)"></app-dali-blind>
  </div>
  <ng-template *ngIf="!deviceIsDali" #equipmentPropertyContainer></ng-template>
    <div class="margin-top40 flex-row flex-end">
      <ion-button size="default" class="square-secondary margin-right5" color="ocean-blue" (click)="onCancel()">{{ 'cancel' | translate }}</ion-button>
      <ion-button size="default" class="square-secondary" (click)="onApply()" color="ocean-blue">{{ 'apply' | translate }}</ion-button>
    </div>
</div>

export class Property {
    id: string;
    name: string;
    type: number;
    inComm: {communicationType: number, address: string};
    outComm: {communicationType: number, address: string};
    security: any;
    availableInUserScene: boolean;
    value: any;
    isConditionProperty: boolean;
    isActuatorProperty: boolean;
    data?: any;



    static isHvacMutex(property: Property) {
     return property.type >= 6500 && property.type <= 6749;
    }

    static isBlindsMutex(property: Property) {
      return property.type >= 10500 && property.type <= 10549;
    }

    static isBlinds(property: Property) {
      return property.type >= 10500 && property.type <= 10549;
    }

    static isFanSpeedMutex(property: Property) {
      return property.type >= 5300 && property.type <= 5339;
    }

    static isRoomTemperatureCurrent(property: Property) {
      return property.type >= 6200 && property.type <= 6249;
    }

    static isRoomTemperatureSet(property: Property) {
      return property.type >= 6250 && property.type <= 6299;
    }

    static isBathroomTemperatureSet(property: Property) {
      return property.type >= 6350 && property.type <= 6399;
    }

    static isBathroomTemperatureCurrent(property: Property) {
      return property.type >= 6300 && property.type <= 6349;
    }

    static isDimmableLight(property: Property) {
      return property.type >= 10800 && property.type <= 10849;
    }

    static isHvacRegulation(property: Property) {
      return property.type >= 5950 && property.type <= 5999;
    }

    static isFloorConvectorSpeedMutex(property: Property) {
      return property.type >= 6950 && property.type <= 6979;
    }

    static isHvacControl(property: Property): boolean {
      return (property.type >= 6750 && property.type <= 6799) || // HvacRoomControlRadiator
      (property.type >= 6800 && property.type <= 6849) || // HvacBathroomControlRadiator
      (property.type >= 6850 && property.type <= 6899) || // HvacRoomControlFloorheating
      (property.type >= 6900 && property.type <= 6949) || // HvacBathroomControlFloorheating
      (property.type >= 6550 && property.type <= 6599) || // HvacRoomControlFancoilLow
      (property.type >= 6600 && property.type <= 6649) || // HvacRoomControlFancoilMedium
      (property.type >= 6650 && property.type <= 6699) || // HvacRoomControlFancoilHigh
      (property.type >= 6700 && property.type <= 6749) || // HvacRoomControlFancoilAuto
      (property.type >= 7000 && property.type <= 7049); // HvacRoomControlFloorconvector
    }

    static isHvacOff(property: Property) {
      return property.type >= 6500 && property.type <= 6549;
    }

    static isHvacLow(property: Property) {
      return property.type >= 6550 && property.type <= 6599;
    }

    static isHvacAuto(property: Property) {
      return property.type >= 6700 && property.type <= 6749;
    }

    static isHvacStatus(property: Property) {
      return property.type >= 5000 && property.type <= 5049;
    }

    static isFanCoilV1(property: Property) {
      return property.type >= 5300 && property.type <= 5309;
    }

    static isFanCoilV2(property: Property) {
      return property.type >= 5310 && property.type <= 5319;
    }

    static isFanCoilV3(property: Property) {
      return property.type >= 5320 && property.type <= 5329;
    }

    static isFanCoilVAuto(property: Property) {
      return property.type >= 5330 && property.type <= 5339;
    }

    static isBathroomControlRadiator(property: Property) {
      return property.type >= 6800 && property.type <= 6849;
    }

    static isBathroomControlFloorHeating(property: Property) {
      return property.type >= 6900 && property.type <= 6949;
    }

    static isBathroomFloorHeating(property: Property) {
      return property.type >= 5500 && property.type <= 5549;
    }

    static isBathroomRadiator(property: Property) {
      return property.type >= 5400 && property.type <= 5449;
    }

    static isHvacMode(property: Property) {
      return property.type >= 5200 && property.type <= 5249;
    }

    static isDoorOrBalconyDoor(property: Property) {
      return (property.type >= 1000 && property.type <= 1049) || // Door
      (property.type >= 1100 && property.type <= 1149); // BalconyDoor
    }

    static isDoor(property: Property) {
      return (property.type >= 1000 && property.type <= 1049) // Door
    }

    static isWindow(property: Property) {
      return (property.type >= 1050 && property.type <= 1099); // Window
    }

    static isRoomArmed(property: Property) {
      return (property.type >= 8000 && property.type <= 8049);
    }

    static propertyIsActive(property: Property) {
      return (property.value !== undefined && property.value !== null && Number(property.value) !== 0);
    }

    static isOutputMode(property: Property) {
      return (property.type >= 12110 && property.type <= 12149);
    }

    static isBlindV2(property: Property) {
      return (property.type >= 11100 && property.type <= 11200);
    }

    static isBlindV2Up(property: Property) {
      return (property.type >= 11100 && property.type <= 11200) && (property.type - 11100) % 10 == 0;
    }
    static isBlindV2Down(property: Property) {
      return (property.type >= 11101 && property.type <= 11200) && (property.type - 11100) % 10 == 1;
    }
    static isBlindsV2Percent(property: Property) {
      return (property.type >= 11102 && property.type <= 11200) && (property.type - 11100) % 10 == 2;
    }
    static isBlindV2TimeUp(property: Property) {
      return (property.type >= 11103 && property.type <= 11200) && (property.type - 11100) % 10 == 3;
    }
    static isBlindV2TimeDown(property: Property) {
      return (property.type >= 11104 && property.type <= 11200) && (property.type - 11100) % 10 == 4;
    }
    static isBlindV2Direction(property: Property) {
      return (property.type >= 11105 && property.type <= 11200) && (property.type - 11100) % 10 == 5;
    }
}
